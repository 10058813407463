
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronRightIcon } from '@heroicons/vue/outline';
import { XCircleIcon } from '@heroicons/vue/solid';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { Form } from 'vee-validate';
import { object, string, array } from 'yup';
import TextField from '@/components/TextField.vue';
import FormSwitch from '@/components/FormSwitch.vue';
import { parseRole } from '@/components/utils';
import rolesList from '@/constants/availableRoles';
import { createUser } from '@/api/users.api';

export default {
  components: {
    ChevronRightIcon,
    SystemStatusBadge,
    XCircleIcon,
    Form,
    TextField,
    FormSwitch,
  },
  setup(): Record<string, unknown> {
    const router = useRouter();
    const onSubmit = async (values: any) => {
      try {
        const registeredUser = (await createUser(values)).data;
        router.push({ path: `/users/${registeredUser.id}` });
      } catch (e) {
        error.value = 'Something went wrong regstering the user';
      }
    };

    const error = ref('');
    const loading = ref(false);
    const users = ref([]);
    const euEnv = process.env.VUE_APP_EU_ENV;

    const schema = object().shape({
      email: string()
        .email('Invalid email value')
        .required('Email is required'),
      password: string()
        .min(16, 'Password length needs to be at least 16 character long')
        .matches(
          /^(?=.*[a-z])/,
          'Password must contain at least one lowercase character'
        )
        .matches(
          /^(?=.*[A-Z])/,
          'Password must contain at least one uppercase character'
        )
        .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
        .test('password-required', 'Password is required', function (value) {
          if (euEnv) {
            return true;
          } else {
            return !!value;
          }
        }),
      roles: array().of(string().required()),
    });

    return {
      error,
      loading,
      users,
      rolesList,
      onSubmit,
      parseRole,
      schema,
      euEnv,
    };
  },
};
